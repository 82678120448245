<template>
  <div>
    <delivery-info></delivery-info>
  </div>

</template>

<script>
import DeliveryInfo from './components/DeliveryInfo'

export default {
  components: {
    DeliveryInfo,
  },
}
</script>

<style lang="scss">

</style>
