<template>
  <!-- BODY -->
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-overlay
      :show="showOverlay"
      variant="white"
    >
      <!-- Form -->
      <b-form
        class="p-2 note-form"
        @submit.prevent="handleSubmit(submitForm)"
        @reset.prevent="resetFormData"
      >
        <div v-if="delivery_types && delivery_types.length && formLoading === false">
          <div>
            <validation-provider
              #default="validationContext"
              rules="required"
              name="Tipo Scarico"
            >
              <b-form-group
                v-if="note_data"
                label="Tipo Scarico"
                class="mb-0"
              >
                <div
                  v-for="delivery_type in delivery_types"
                  :key="delivery_type.id"
                  class="mb-1"
                >

                  <b-form-radio
                    v-model="formData.delivery_type"
                    name="delivery_type"
                    :value="delivery_type.fg_tipo_scarico"
                    class="custom-control-primary"
                    :class="delivery_type.slug_tipo"
                    :state="getValidationState(validationContext)"
                    :disabled="read_only"
                    @change="clearReason()"
                  >
                    <span
                      class="text-uppercase ds-tipo rounded"
                      :class="delivery_type.slug_tipo"
                    >
                      {{ delivery_type.ds_tipo }}
                    </span>
                  </b-form-radio>
                  <Transition name="fade">
                    <div
                      v-if="delivery_type.reasons && delivery_type.reasons.length && formData.delivery_type == delivery_type.fg_tipo_scarico"
                      class="p-1 mt-1 reasons rounded"
                    >

                      <div class="mb-1">
                        <strong>MOTIVAZIONE:</strong>
                      </div>
                      <div
                        v-for="reason in delivery_type.reasons"
                        :key="reason.c_causale_consegne"
                        class="mb-1"
                      >
                        <b-form-radio
                          v-model="formData.delivery_type_reason"
                          :name="'delivery_type_reason_'+ delivery_type.id"
                          :value="reason.c_causale_consegne"
                          :disabled="read_only"
                          class="custom-control-primary"
                        >
                          <span class="text-uppercase">{{ reason.ds_causale_consegne }}</span>
                        </b-form-radio>
                      </div>
                      <div>
                        <b-form-radio
                          v-model="formData.delivery_type_reason"
                          name="delivery_type_reason_generic"
                          value="*"
                          :disabled="read_only"
                          class="custom-control-primary"
                        >
                          <span class="text-uppercase">ALTRO</span>
                        </b-form-radio>
                      </div>
                    </div>
                  </Transition>
                  <div class="clearfix" />

                </div>
                <b-form-invalid-feedback class="invalid-delivery-type">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
          <br>
          <!-- Note -->
          <validation-provider
            #default="validationContext"
            rules=""
            name="Nota"
          >
            <div class="mb-1">
              <b-form-group
                label="Nota"
                label-for="note"
              >
                <b-form-textarea
                  id="note"
                  v-model="formData.note"
                  placeholder=""
                  trim
                  rows="2"
                  :readonly="read_only"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </validation-provider>

          <div v-if="!read_only" class="upload-foto">
            <label>Foto (Solo .jpg, Max 4 files)</label>
            <vue-dropzone
              id="note-attachment"
              ref="NoteAttachment"
              :options="dropzoneOptions"
              @vdropzone-sending="sendingAttachmentEvent"
              @vdropzone-complete="attachmentsUploadSuccess"
              @vdropzone-error="attachmentsUploadError"
            />
          </div>

          <div v-if="note_data && note_data.fg_livello">
            <NoteAttachments
              :key="attachmentsKey"
              :note_data="note_data"
            />
          </div>

          <!-- Form Actions -->
          <div v-if="!read_only" class="d-flex mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
            >
              Salva
            </b-button>

            <b-button
              v-if="note_data && (note_data.c_causale_consegne || note_data.fg_tipo_scarico)"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              variant="danger"
              @click="deleteNote"
            >
              Elimina nota
            </b-button>

            <b-button
              v-if="note_data && !note_data.fg_tipo_scarico"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              variant="outline-secondary"
              @click="resetFormData"
            >
              Reset
            </b-button>

          </div>

        </div>

      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormRadio, BFormFile, BButton, BFormTextarea, BFormInvalidFeedback, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations_custom'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import store from '@/store'
import appConfig from '../../../../config.json'
import NoteAttachments from '../note/NoteAttachments'

export default {
  components: {
    // Bootstrap Vue Components
    BForm,
    BFormGroup,
    BFormRadio,
    BButton,
    BFormTextarea,
    BFormInvalidFeedback,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vueDropzone: vue2Dropzone,
    NoteAttachments,
  },
  directives: {
    Ripple,
  },
  props: {
    hide: Function,
    note_data: {
      type: Object,
      required: true,
    },
    read_only: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${appConfig[store.state.scope.appScope].api_base_url}note/attachment/create`,
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        maxThumbnailFilesize: 52428800,
        maxFilesize: 52428800,
        maxFiles: 4,
        dictDefaultMessage: 'Aggiungi foto',
        dictFileTooBig: 'File troppo pesante ({{filesize}}MiB). Dimensione massima: {{maxFilesize}}MiB.',
        dictInvalidFileType: 'Tipo non valido',
        dictRemoveFile: 'Rimuovi',
        dictCancelUpload: 'Rimuovi',
        dictCancelUploadConfirmation: 'Sei sicuro di voler fermare il caricamento?',
        acceptedFiles: 'image/*',
        autoProcessQueue: false,
        parallelUploads: 4,
        addRemoveLinks: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-xsrf-token': this.$cookies.get('XSRF-TOKEN') ?? '',
        },
      },
      required,
      max,
      showOverlay: false,
      formLoading: false,
      formSending: false,
      fg_livello: null,
      delivery_types: [],
      attachmentsKey: 0,
    }
  },
  watch: {
    note_data: {
      handler(newVal) {
        this.showOverlay = true
        this.resetFormData()
        this.note_data = newVal

        // eslint-disable-next-line no-restricted-globals
        if (newVal.fg_livello !== null) {
          this.formData = {
            delivery_type: this.note_data.fg_tipo_scarico ?? null,
            delivery_type_reason: this.note_data.c_causale_consegne ?? null,
            note: this.note_data.nota_consegna ?? '',
          }

          this.formLoading = true

          this.$http.get(`/note/delivery-types/${newVal.fg_livello}`).then(res => {
            this.delivery_types = res.data.data.delivery_types
            // this.fg_livello = newVal.fg_livello
            this.showOverlay = false
            this.formLoading = false
          }).catch(() => {
            this.showOverlay = false
          })
        }
      },
      deep: true,
    },
  },
  methods: {
    submitForm() {
      this.showOverlay = true
      this.formSending = true

      const noteSubmitData = { ...this.formData, ...this.note_data }
      this.$http.post('/note/create', noteSubmitData).then(() => {
        const attachments = this.$refs.NoteAttachment.getQueuedFiles()
        this.note_data.nota_consegna = this.formData.note
        this.note_data.fg_tipo_scarico = this.formData.delivery_type

        if (this.formData.delivery_type_reason) {
          this.note_data.c_causale_consegne = this.formData.delivery_type_reason
        }
        if (attachments.length) {
          this.$refs.NoteAttachment.processQueue()
        } else {
          const note = noteSubmitData
          this.showOverlay = false
          // this.note_data.fg_livello = null
          this.$emit('noteSubmitted', note)
          // this.hide()
        }

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Nota salvata',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'La nota è stata salvata con successo.',
          },
        })

        // if (res.data.success) {
        // this.resetForm()
        // }
      }).catch(() => {
        this.showOverlay = false
      })
    },
    deleteNote() {
      this.showOverlay = true

      /* Carico l'elenco degli allegati */
      this.$http.delete(`/note/delete/${this.note_data.c_carico}/${this.note_data.n_scarico ?? 0}/${this.note_data.n_anno ?? 0}/${this.note_data.c_numeratore ?? '*'}/${this.note_data.n_ordine ?? 0}/${this.note_data.c_collo ?? '*'}/`).then(res => {
        this.note_data.nota_consegna = ''
        this.note_data.fg_tipo_scarico = null
        this.note_data.c_causale_consegne = null

        this.resetFormData()
        this.$emit('noteSubmitted', this.note_data)

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Nota eliminata',
            icon: 'Trash2Icon',
            variant: 'warning',
            text: 'La nota è stata eliminata con successo.',
          },
        })

        this.showOverlay = false
      }).catch(() => {
        this.showOverlay = false
      })
    },
    sendingAttachmentEvent(file, xhr, formData) {
      this.showOverlay = true
      formData.append('c_carico', this.note_data.c_carico)
      formData.append('n_scarico', this.note_data.n_scarico)
      formData.append('n_anno', this.note_data.n_anno ?? 0)
      formData.append('c_numeratore', this.note_data.c_numeratore ?? '*')
      formData.append('n_ordine', this.note_data.n_ordine ?? 0)
      formData.append('c_collo', this.note_data.c_collo ?? '*')
      formData.append('uuid', file.upload.uuid)
    },
    attachmentsUploadSuccess(response) {
      const noteSubmitData = { ...this.formData, ...this.note_data }
      this.$refs.NoteAttachment.removeAllFiles()
      setTimeout(() => {
        this.reloadAttachments()
        this.showOverlay = false
      }, 1500)

      this.$emit('noteSubmitted', noteSubmitData)
    },
    attachmentsUploadError(file, message) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Errore upload file',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: message.message,
        },
      })
    },
    reloadAttachments() {
      this.attachmentsKey += 1
    },
  },
  setup(props, context) {
    const blankFormData = {
      delivery_type: null,
      delivery_type_reason: null,
      note: '',
    }

    const formData = ref(JSON.parse(JSON.stringify(blankFormData)))

    const resetFormData = () => {
      formData.value = JSON.parse(JSON.stringify(blankFormData))
      if (context && context.refs && context.refs.NoteAttachment) {
        context.refs.NoteAttachment.removeAllFiles()
      }
    }

    const clearReason = () => {
      formData.value.delivery_type_reason = null
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetFormData)

    return {
      formData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetFormData,
      clearReason,
      close,
    }
  },
}
</script>

<style lang="scss">
@import "~/src/assets/scss/variables/_variables.scss";

.note-form {

  min-height: 260px;

  .form-file-text {
    font-size: 1rem !important;
  }

  label {
    font-size: 1rem !important;
  }

  .fade-enter-active {
    transition: all 0.8s ease;
  }

  .invalid-delivery-type {
    display: block !important;
    margin-top: 0 !important;
  }

  .fade-enter-from {
    opacity: 0;
  }

  .fade-leave-active {
    transition: opacity 0s ease;
  }

  .fade-leave-from {
    opacity: 0;
  }

  .note-form {
    min-height: 400px;
  }

  .reasons {
    background: #f6f6f6;
  }

  span.ds-tipo {

    background: #f6f6f6;
    padding: 2px 8px;
    color: #5e5873 !important;

    &.senza-anomalie {
      background: $senza-anomalie-bg;
    }

    &.non-effettuato {
      background: $non-effettuato-bg;
    }

    &.con-riserva {
      background: $con-riserva-bg;
    }

    &.cliente-assente {
      background: $cliente-assente-bg;
    }

  }

  #note-attachment {
    &.dropzone {
      min-height: 60px;
      border-radius: 0.357rem;
      padding: 6px;

      .dz-preview{
        min-height: 120px;
        width: 120px;
        height: 120px;
        margin: 11px;
        z-index: 2 !important;
        &:hover{
          z-index: 2 !important;
        }
        .dz-image{
          z-index: 1 !important;
        }
        .dz-details{
          padding: 0;
          background-color: #000000;
          z-index: 2 !important;
          .dz-filename{
            display: none;
          }
          .dz-size{
            width: 100%;
            top: 0;
            padding: 6px 2px;
            text-align: center;
          }
        }
      }

      .dz-message {
        margin: 15px;
      }

      .dz-remove{
        padding: 2px;
        border: none;
        margin: 0;
        background: #ea5455;
        font-weight: normal;
        width: 100%;
        bottom: 0;
        opacity: 1;
        z-index: 3 !important;
      }

    }

    &.vue-dropzone {
      border: 1px solid #d8d6de !important;
    }

  }
}
</style>
