<template>
  <div>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-overlay
        :show="showOverlay"
        variant="white"
      >
        <!-- Form -->
        <b-form
          class="p-2 ddt-data-form text-dark"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetFormData"
        >
          <div v-if="checks_list && checks_list.length && formLoading === false">
            <div>
              <p><small>I dati inseriti in questo form verranno riportati sui documenti <strong>DDT</strong> relativi a questa consegna.<br></small></p>

              <!-- Pin di controllo cliente -->
              <validation-provider
                rules=""
                name="Pin di controllo"
              >
                <div class="mb-2">
                  <div
                    v-if="control_pin_enabled && pin_required"
                    class="pin-wrapper rounded pb-1"
                  >
                    <h4 class="text-uppercase text-center font-weight-bold mb-0 rounded form-section-title bg-primary text-white">
                      PIN DI CONTROLLO

                      <feather-icon
                        class="ml-25 mb-25"
                        icon="InfoIcon"
                        size="16"
                        v-b-modal.modal-pin-info
                      />

                    </h4>
                    <p class="mb-1">
                      <small>Inserire il <strong>PIN DI CONTROLLO</strong> se fornito dal ricevente</small>
                    </p>
                    <b-form-group
                      label=""
                      label-for="pin"
                    >
                      <b-form-input
                        id="pin-1"
                        class="pin-input"
                        v-model="pin_1"
                        type="number"
                        min="0"
                        max="9"
                        trim
                        :readonly="read_only || pin_readonly"
                        @input="nextPinInput(1)"
                      />
                      <b-form-input
                        id="pin-2"
                        class="pin-input"
                        v-model="pin_2"
                        type="number"
                        min="0"
                        max="9"
                        trim
                        :readonly="read_only || pin_readonly"
                        @input="nextPinInput(2)"
                        @keydown.delete.prevent="nextPinInput(2, true)"
                      />
                      <b-form-input
                        id="pin-3"
                        class="pin-input"
                        v-model="pin_3"
                        type="number"
                        min="0"
                        max="9"
                        trim
                        :readonly="read_only || pin_readonly"
                        @input="nextPinInput(3)"
                        @keydown.delete.prevent="nextPinInput(3, true)"
                      />
                      <b-form-input
                        id="pin-4"
                        class="pin-input"
                        v-model="pin_4"
                        type="number"
                        min="0"
                        max="9"
                        trim
                        :readonly="read_only || pin_readonly"
                        @input="nextPinInput(4)"
                        @keydown.delete.prevent="nextPinInput(4, true)"
                      />

                    </b-form-group>

                    <b-spinner
                      small
                      class=""
                      variant="light"
                      label="Small Spinner"
                      v-if="pin_readonly"
                    />

                    <b-badge
                      v-if="(String(controlPin).length === 4 && ((is_pin_valid && is_pin_valid === 'valid') || (!is_pin_valid && !pin_readonly && pin_status === 'valid')))"
                      variant="success">
                      Ottimo! PIN valido!
                    </b-badge>

                    <b-badge
                      v-if="(String(controlPin).length === 4 && ((is_pin_valid && is_pin_valid === 'not_valid') || (!is_pin_valid && !pin_readonly && pin_status === 'wrong')))"
                      variant="danger">
                      Ops! PIN non valido!
                    </b-badge>

                  </div>
                </div>
              </validation-provider>

              <div
                v-if="checks_list.length"
                label=""
                class="mb-0"
              >
                <h4 class="text-uppercase font-weight-bold mb-1 rounded form-section-title bg-primary text-white">
                  Controlli effettuati allo scarico
                </h4>
                <div
                  v-for="check in checks_list"
                  :key="check.id"
                  class="mb-1 check-wrapper rounded"
                >
                  <div
                    v-if="check.values && check.values.length"
                    class=""
                  >
                    <div class="text-uppercase mb-50">
                      <strong>{{ check.ds_tipo }}</strong>
                    </div>
                    <validation-provider
                      #default="validationContext"
                      rules="required"
                      :name="check.slug_tipo"
                    >
                      <b-form-group
                        class="mb-0"
                        label=""
                      >
                        <div
                          v-for="(value, index) in check.values"
                          :key="value.slug_valore+value.id"
                          class="d-inline"
                        >
                          <b-form-radio
                            :key="value.slug_valore"
                            v-model="formChecks[check.slug_tipo]"
                            :name="check.slug_tipo"
                            :value="value.valore"
                            class="custom-control-primary mr-1"
                            :class="value.slug_valore"
                            :state="getValidationState(validationContext)"
                            :disabled="read_only"
                          >
                            <span
                              class="ds-tipo rounded"
                              :class="value.slug_valore"
                            >
                              {{ value.ds_valore }}
                            </span>
                          </b-form-radio>
                        </div>
                      </b-form-group>
                      <b-form-invalid-feedback class="invalid-delivery-type">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                </div>

              </div>

            </div>
            <br>
            <!-- Autista -->
            <validation-provider
              #default="validationContext"
              rules="required"
              name="Autista"
            >
              <div class="mb-2">
                <h4 class="text-uppercase font-weight-bold mb-1 rounded form-section-title bg-primary text-white">
                  Autista
                </h4>
                <p class="mb-25">
                  <small>Inserire <strong>nome e cognome</strong> dell'autista</small>
                </p>
                <b-form-group
                  label=""
                  label-for="driver"
                >
                  <b-form-input
                    id="driver"
                    v-model="formData.driver"
                    placeholder="Nome e cognome"
                    trim
                    :readonly="read_only"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </validation-provider>

            <!-- Ricevente -->
            <validation-provider
              #default="validationContext"
              rules="required"
              name="Ricevente"
              class="d-block"
            >
              <div class="mb-2">
                <h4 class="text-uppercase font-weight-bold mb-1 rounded form-section-title bg-primary text-white">
                  Ricevente
                </h4>
                <p class="mb-25">
                  <small>Inserire <strong>nome e cognome</strong> del ricevente che firmerà i documenti DDT</small>
                </p>
                <b-form-group
                  label=""
                  label-for="receiver"
                >
                  <b-form-input
                    id="receiver"
                    v-model="formData.receiver"
                    placeholder="Nome e cognome"
                    trim
                    :readonly="read_only"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </validation-provider>

            <div class="clearfix" />

            <!-- Form Actions -->
            <div
              v-if="!read_only"
              class="d-block mt-3"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-2"
                type="submit"
              >
                Salva
              </b-button>
            </div>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>

    <b-modal
      id="modal-pin-info"
      ok-only
      ok-title="CHIUDI"
      modal-class="modal-secondary"
      centered
      title="Cos'è il PIN di controllo?"
    >
      <b-card-text>
        <p>L'inserimento del <strong>codice PIN corretto</strong> certifica che la firma sui documenti DDT è stata effettuata dal cliente.</p>

        <p>Il <strong>PIN di controllo</strong> viene inviato, prima della consegna, esclusivamente al cliente
          ed è presente nel documento <strong>"Aknowledgements Orders"</strong> che viene inviato via e-mail.</p>
      </b-card-text>
    </b-modal>
  </div>

</template>

<script>
import {
  BForm, BFormGroup, BFormRadio, BButton, BFormInput, BFormInvalidFeedback, BOverlay, BSpinner, BBadge, BModal, VBModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations_custom'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import appConfig from '../../../../config.json'
// import store from '@/store'

export default {
  components: {
    // Bootstrap Vue Components
    BForm,
    BFormGroup,
    BFormRadio,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    BOverlay,
    BSpinner,
    BBadge,
    BModal,
    VBModal,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    hide: Function,
    c_carico: {
      type: String,
      required: true,
    },
    n_scarico: {
      type: Number,
      required: true,
    },
    c_cliente: {
      type: Number,
      required: true,
    },
    ddt: {
      type: Array,
      required: true,
    },
    driver: {
      type: String,
    },
    receiver: {
      type: String,
    },
    checks: {
      type: String,
    },
    read_only: {
      type: Boolean,
      required: true,
    },
    pin_required: {
      type: String,
    },
    pin_status: {
      type: String,
    },
    pin: {
      type: String,
    },
  },
  data() {
    return {
      required,
      max,
      showOverlay: false,
      formLoading: false,
      checks_list: [],
      formChecksProxy: {}, // https://riptutorial.com/vue-js/example/28978/using-computed-setters-for-v-model | https://vuejs.org/guide/essentials/computed.html#writable-computed
      pin_1: this.pin ? String(this.pin.substring(0, 1)) : '',
      pin_2: this.pin ? String(this.pin.substring(1, 2)) : '',
      pin_3: this.pin ? String(this.pin.substring(2, 3)) : '',
      pin_4: this.pin ? String(this.pin.substring(3, 4)) : '',
      is_pin_valid: null,
      pin_readonly: false,
      valid_pin: false,
      control_pin_enabled: appConfig.control_pin_enabled ?? false,
    }
  },
  computed: {
    formChecks: {
      get() {
        const formChecks = {}
        const savedChecks = this.checks.split(';') // splitto la stringa dei controlli in props

        if (this.checks_list.length) {
          this.checks_list.forEach( // per ogni controllo
            element => {
              element.values.forEach(value => { // ciclo le opzioni
                if (savedChecks.includes(value.slug_valore)) { // se lo slug è tra i controlli effettuati
                  formChecks[element.slug_tipo] = value.valore // setto il valore del form corretto
                } else {
                  formChecks[element.slug_tipo] = element.values[0].valore // altrimenti setto il primo valore
                }
              })
            },
          )
        }

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.formChecksProxy = formChecks
        return formChecks
      },
      set(val) {
        this.formChecksProxy = val
      },
    },
    controlPin() {
      return parseInt(this.pin_1 + this.pin_2 + this.pin_3 + this.pin_4)
    },
  },
  watch: {
    // whenever question changes, this function will run
    controlPin: function (newPin) {
      if (String(newPin).length >= 4) {
        this.pin_readonly = true
        this.$http.get(`/cargo/in-progress/delivery/validate-pin/${this.c_carico}/${this.c_cliente}/${newPin}`).then(res => {
          this.is_pin_valid = res.data.data.result
          this.pin_readonly = false
          this.valid_pin = newPin
        }).catch(() => {
          this.pin_readonly = false
        })
      }
      else {
        this.is_pin_valid = null
        this.valid_pin = ''
      }
    },
  },
  created() {
    this.showOverlay = true

    this.$http.get('/cargo/delivery/checks/list').then(res => {
      this.checks_list = res.data.data.checks_types
      this.showOverlay = false
    }).catch(() => {
      this.showOverlay = false
    })
  },
  methods: {
    submitForm() {
      this.showOverlay = true

      // eslint-disable-next-line camelcase
      let control_pin = null

      if (this.control_pin_enabled) {

        control_pin = this.controlPin

        if (this.is_pin_valid && this.valid_pin && this.control_pin_enabled) {
          control_pin = this.valid_pin
        }
      }

      const SubmitData = {
        ...this.formData, c_carico: this.c_carico, n_scarico: this.n_scarico, checks: { ...this.formChecks }, ddt: { ...this.ddt }, pin: control_pin,
      }

      this.$http.post('/cargo/delivery/ddt-data/set', SubmitData).then(res => {
        this.showOverlay = false

        this.$emit('ddtDataSubmitted')

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Dati DDT salvati',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'I dati DDT sono stati salvati con successo.',
          },
        })
      }).catch(() => {
        this.showOverlay = false
      })
    },
    nextPinInput(pin_number, is_delete = false){

      let next_pin_number = pin_number + 1
      if(!is_delete) {
        if (document.getElementById('pin-' + pin_number).value !== '') {
          if (document.getElementById('pin-' + pin_number).value.length > 1) {
            document.getElementById('pin-' + pin_number).value = String(document.getElementById('pin-' + pin_number).value).slice(-1)
          }
          if (pin_number < 4){
            document.getElementById('pin-' + next_pin_number).focus()
          }
          else{
            document.getElementById('pin-' + pin_number).blur()
          }
        } else {
          let prev_pin_number = pin_number - 1
          if (prev_pin_number > 0) {
            document.getElementById('pin-' + prev_pin_number).focus()
          }
        }
      }
      else{ // se premo il pulsante cancella
        let prev_pin_number = pin_number - 1 // calcolo posizione precedente
        if (prev_pin_number > 0) {
          if(document.getElementById('pin-' + pin_number).value == '') {
            document.getElementById('pin-' + prev_pin_number).focus() // mi sposto sulla casella precedente
          }
        }

        document.getElementById('pin-' + pin_number).value = '' // cancello il valore presente
      }
    },
  },
  setup(props, context) {
    const blankFormData = {
      receiver: props.receiver ?? '',
      driver: props.driver ?? '',
    }

    const formData = ref(JSON.parse(JSON.stringify(blankFormData)))

    const resetFormData = () => {
      formData.value = JSON.parse(JSON.stringify(blankFormData))
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetFormData)

    return {
      formData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetFormData,
      close,
    }
  },
}
</script>

<style lang="scss">
@import "~/src/assets/scss/variables/_variables.scss";

.ddt-data-form {

  min-height: 260px;

  p{
    line-height: 1.1;
    small{
      line-height: 1.1;
    }
  }

  .pin-wrapper{

    text-align: center;
    padding-bottom: 4px;
    padding-top: 4px;
    background: #636363;
    color: #ffffff;

    .pin-input{
      display: inline-block;
      width: 38px !important;
      text-align: center;
      margin: 0 3px;
      background: white;
      padding: 2px !important;
      color: #000000;
      font-size: 24px;
      font-weight: bold;
      box-shadow: inset 1px 1px 3px 1px rgba(0,0,0,0.25);
    }
  }

  .form-section-title{
    padding: 8px 8px 6px;
    background: #f6f6f6;
    line-height: 1.1;
  }

  .check-wrapper {
    padding: 8px 8px 10px;
    border: 2px solid #f6f6f6;
    background: #f6f6f6;
  }

  label {
    font-size: 1rem !important;
  }

  .custom-control{
    &.custom-radio{
      display: inline !important;

      .custom-control-input{
        &.is-valid{
          ~.custom-control-label {
            .ds-tipo {
              color: #222222 !important;
            }
          }
        }
      }
    }
  }

  @media (max-width: 340px){
    .custom-control{
      &.custom-radio{
        display: block !important;
      }
    }
  }
}
</style>
