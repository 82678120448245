// import store from '@/store'
import Vue from 'vue'
import ToastificationContent from '@core_custom/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import appConfig from '../../../config.json' // Arredo3 config

const openApp = {
  methods: {
    openApp(cCarico, nConsegna = 0, reset = 0) {
      const token = encodeURI(localStorage.getItem('userToken') ?? '')
      const vehicle = encodeURI(localStorage.getItem('vehicleCode') ?? 0)
      const appVersion = appConfig.vue_app_ubsign_app_version ?? '030100'
      // const appName = process.env.VUE_APP_UBSIGN_APP_NAME ?? 'UBSign3.1.0.apk'
      const appCompanyId = appConfig[store.state.scope.appScope].vue_app_ubsign_company_id ?? '1'

      const appLocation = `ubsign://home.html?token=${token}&automezzo=${vehicle}&codcarico=${cCarico}&scarico=${nConsegna}&link=${appCompanyId}&ver=${appVersion}&reset=${reset}`

      // FLAG RESET
      // 0 => Nessun reset
      // 1 => Operazione normale
      // 2 => Operazione speciale per eliminare tutti i DDT dal tablet
      try {
        window.location = appLocation
      } catch (e) {
        console.error(e)
        Vue.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Impossibile aprire l\'app UbSign',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Verificare che l\'applicazione si correttamente installata.',
          },
        })
      }
    },
  },
}

export {
  // eslint-disable-next-line import/prefer-default-export
  openApp,
}
