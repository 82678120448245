<template>
  <div>

    <b-button
      v-if="googleCompleteDirectionsLink"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      size="sm"
      variant="primary"
      :href="googleCompleteDirectionsLink"
      target="_blank"
      class="mb-1"
    >
      <feather-icon
        icon="MapIcon"
        class="mr-50"
        size="16"
      />
      <span class="align-middle">INDICAZIONI STRADALI COMPLETE</span>
    </b-button>

    <l-map
      style="height: 480px"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      :bounds="mapBounds"
      :padding-top-left="mapPadding"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-marker
        :lat-lng="head_office_lat_lng"
      >
        <l-icon :icon-url="`/images/maps/markers/home.png`" :icon-size="iconSize"/>
        <l-popup class="text-uppercase"><strong>{{ head_office }}</strong></l-popup>
      </l-marker>
      <l-marker
        v-for="delivery in deliveries"
        :key="delivery.n_consegna"
        :lat-lng="[delivery.n_latitudine, delivery.n_longitudine]"
      >
        <l-icon :icon-url="`/images/maps/markers/marker-${delivery.n_sequenza}.png`" :icon-size="iconSize"/>
        <l-popup :options="mapPopupOptions">
          <div><strong>{{ delivery.ds_destinazione }}</strong></div>
          <div>
            <small>
              {{ delivery.indirizzo_consegna }}<br>
              {{ `${delivery.citta_consegna} ${delivery.n_cap_consegna} (${delivery.c_provincia_consegna})` }}
            </small>
          </div>
          <div class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
              :href="'https://www.google.com/maps/dir/?api=1&destination='+delivery.n_latitudine + ',' + delivery.n_longitudine"
              target="_blank"
            >
              <feather-icon
                icon="MapPinIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">INDICAZIONI STRADALI</span>
            </b-button>
          </div>
        </l-popup>
      </l-marker>

    </l-map>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import L, { Icon } from 'leaflet'
import { LMap, LTileLayer, LMarker, LCircleMarker, LIcon, LPopup, } from 'vue2-leaflet'
import Ripple from 'vue-ripple-directive'
import 'leaflet/dist/leaflet.css'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: {
    BRow,
    BCol,
    BButton,
    LMap,
    LTileLayer,
    LMarker,
    LCircleMarker,
    LIcon,
    LPopup,
  },
  directives: {
    Ripple,
  },
  props: {
    deliveries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        `&copy; <a target="_blank" title="${process.env.VUE_APP_HEAD_OFFICE}" href="${process.env.VUE_APP_HEAD_OFFICE_WEBSITE}">${process.env.VUE_APP_HEAD_OFFICE}</a>`,
      zoom: 0,
      center: [process.env.VUE_APP_HEAD_OFFICE_LNG, process.env.VUE_APP_HEAD_OFFICE_LAT],
      head_office: process.env.VUE_APP_HEAD_OFFICE,
      head_office_lat_lng: [process.env.VUE_APP_HEAD_OFFICE_LNG, process.env.VUE_APP_HEAD_OFFICE_LAT],
      markerLatLng: [47.313220, -1.319482],
      iconSize: [26, 37],
      mapPadding: [40, 40],
      mapOptions: {
        dragging: true,
        touchZoom: true,
        tap: true,
      },
      mapPopupOptions: {
        maxWidth: 200,
      },
    }
  },
  computed: {
    mapBounds() {
      const bounds = []
      bounds.push(this.head_office_lat_lng)
      if (this.deliveries.length) {
        this.deliveries.forEach(delivery => {
          bounds.push([delivery.n_latitudine, delivery.n_longitudine])
        })
      }
      return bounds
    },
    googleCompleteDirectionsLink() {
      let link = 'https://www.google.com/maps/dir/Current+Location/'
      if (this.deliveries.length) {
        this.deliveries.forEach(delivery => {
          link += `${delivery.n_latitudine},${delivery.n_longitudine}\\`
        })
      }
      return link
    },
  },
  methods: {},
}
</script>

<style lang="scss">

</style>
