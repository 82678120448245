<template>
  <div>
    <div class="clearfix" />
    <b-overlay
      :show="showOverlay"
      variant="white"
      spinner-small
      class="attachments-overlay"
    >
      <div class="attachments">
        <div
          v-if="attachments && attachments.length"
          class="mt-2"
        >
          <b-row>
            <b-col
              v-for="attach in attachments"
              :key="attach.n_id_allegato"
              cols="6"
              class="mb-1"
            >
              <div class="attach-image">
                <img
                  :src="attach.base64_thumb"
                  :alt="'attach-id-'+attach.n_id_allegato"
                >
                <div class="delete-attach-wrapper">
                  <div
                    class="delete-attach"
                    @click="deleteAttachment(attach.n_id_allegato)"
                  >
                    ELIMINA
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    note_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      attachments: null,
      showOverlay: false,
    }
  },
  created() {
    this.loadAttachments()
  },
  methods: {
    loadAttachments() {
      this.showOverlay = true

      /* Carico l'elenco degli allegati */
      this.$http.get(`/note/attachments/${this.note_data.c_carico}/${this.note_data.n_scarico ?? 0}/${this.note_data.n_anno ?? 0}/${this.note_data.c_numeratore ?? '*'}/${this.note_data.n_ordine ?? 0}/${this.note_data.c_collo ?? '*'}/`).then(res => {
        this.attachments = res.data.data.attachments
        this.showOverlay = false
      }).catch(() => {
        this.showOverlay = false
      })
    },
    deleteAttachment(idAllegato) {
      this.showOverlay = true

      this.$http.delete(`/note/attachment/delete/${idAllegato}`).then(() => {
        this.loadAttachments()

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Foto eliminata',
            icon: 'Trash2Icon',
            variant: 'warning',
            text: 'La foto è stata eliminata con successo.',
          },
        })

      }).catch(() => {
        this.showOverlay = false
      })
    },
  },
}
</script>

<style scoped lang="scss">

  img{
    max-width: 100%;
  }

  .attachments{
    padding: 10px 0;
    .attach-image {
      position: relative;
      img{
        width: 100%;
      }
      .delete-attach-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;

        .delete-attach {
          width: 100%;
          background: black;
          color: white;
          font-size: 13px;
          text-align: center;
          font-weight: bold;
          padding: 2px;
          letter-spacing: 1px;
          cursor: pointer;
        }
      }
    }

  }

</style>
