<template>
  <div class="page-delivery">
    <div class="breadcrumb-wrapper">
      <div v-if="delivery">
        <h2 class="content-header-title float-left pr-1 mb-0">
          Consegna {{ delivery.n_sequenza }}
        </h2>
        <b-breadcrumb
          class="mb-1"
        >
          <b-breadcrumb-item to="/">
            <feather-icon
              icon="HomeIcon"
              size="16"
              class="align-text-top"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item
            to="/carichi"
            class="d-md-inline-block d-sm-none d-none"
          >
            <span>I tuoi carichi</span>
          </b-breadcrumb-item>
          <b-breadcrumb-item
            :to="'/carico/' + delivery.c_carico"
            class="d-md-inline-block d-sm-none d-none"
          >
            <span>Carico {{ delivery.c_carico }}</span>
          </b-breadcrumb-item>
          <b-breadcrumb-item
            active
            class="d-md-inline-block d-sm-none d-none"
          >
            <span>Consegna {{ delivery.n_sequenza }}</span>
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <b-row>
      <b-col
        cols="12"
        xl="4"
        lg="4"
        md="12"
      >
        <b-overlay
          :show="showOverlay"
          variant="white"
        >
          <b-card class="delivery-info-box">
            <div class="p-150">
              <b-row
                v-if="delivery"
                class="no-gutters"
              >
                <!-- Left col -->
                <b-col
                  cols="12"
                  xl="12"
                  class="d-flex justify-content-between flex-column"
                >
                  <!-- User Avatar & Action Buttons -->
                  <div class="d-flex justify-content-start">
                    <div class="d-flex flex-column w-100">
                      <div
                        v-if="parseInt(delivery.fg_consegnato)"
                        class="mb-1"
                      >
                        <b-badge
                          v-if="parseInt(delivery.fg_consegnato)"
                          variant="primary"
                          pill
                          class="badge-round align-self-center delivery-status-badge mr-25"
                          :class="'closed-'+delivery.fg_consegnato+' '+delivery.slug_tipo_scarico"
                        >
                          Consegnata
                        </b-badge>
                      </div>

                      <div class="mb-1">
                        <h4 class="mb-0">
                          [{{ delivery.c_nome_consegna }}]
                        </h4>
                        <h2 class="mb-0">
                          {{ delivery.ds_destinazione }}
                        </h2>

                        <div class="address mt-25  w-100">
                          <small>
                            {{ delivery.indirizzo_consegna }} {{
                              `${delivery.citta_consegna}
                                  ${delivery.n_cap_consegna} (${delivery.c_provincia_consegna})`
                            }}
                          </small>
                        </div>

                      </div>

                      <div
                        v-if="delivery.dt_consegnato"
                        class="mb-1"
                      >
                        <strong class="text-uppercase">Data/Ora di fine consegna:</strong> {{
                          new Intl.DateTimeFormat('en-GB', {
                            dateStyle: 'short',
                            timeStyle: 'medium'
                          }).format(Date.parse(delivery.dt_consegnato))
                        }}
                      </div>

                      <b-row class="no-gutters w-100">
                        <b-col
                          cols="12"
                          class="d-flex align-items-center justify-content-start justify-content-start flex-wrap py-1"
                        >
                          <div
                            v-b-tooltip.hover.v-dark
                            title="Numero colli"
                            class="pr-2"
                          >
                            <small>{{ parseInt(delivery.n_colli) }}</small>
                            <feather-icon
                              class="text-body"
                              icon="PackageIcon"
                              size="21"
                            />
                          </div>

                          <div
                            v-b-tooltip.hover.v-dark
                            title="Volume"
                            class="pb-50 pr-2"
                          >
                            <small>{{ parseFloat(delivery.n_volume) + 'm³' }}</small>
                            <feather-icon
                              class="text-body"
                              icon="BoxIcon"
                              size="21"
                            />
                          </div>
                          <div
                            v-b-tooltip.hover.v-dark
                            title="Apri mappa"
                            class="pb-50 pr-2"
                          >
                            <a
                              :href="'https://www.google.com/maps/dir/?api=1&destination='+delivery.n_latitudine + ',' + delivery.n_longitudine"
                              target="_blank"
                            >
                              <feather-icon
                                class="text-body"
                                icon="MapPinIcon"
                                size="21"
                              />
                            </a>
                          </div>
                          <div
                            v-b-tooltip.hover.v-dark
                            v-b-modal.modal-contacts
                            title="Contatti utili"
                            class="pb-50 pr-2"
                          >
                            <feather-icon
                              class="text-body"
                              icon="PhoneIcon"
                              size="21"
                            />
                          </div>
                          <div
                            v-b-tooltip.hover.v-dark
                            title="Numero ordini"
                            class="pb-50 pr-2"
                          >
                            <feather-icon
                              :badge="delivery.n_ordini"
                              badge-classes="bg-important"
                              class="text-body"
                              icon="ShoppingCartIcon"
                              size="21"
                            />
                          </div>

                        </b-col>

                        <b-col
                          cols="12"
                          class="d-flex align-items-center justify-content-start justify-content-start flex-wrap"
                        >
                          <div
                            v-if="parseInt(delivery.n_ddt_in_arxivar) === delivery.ddt.length"
                            class="my-1"
                          >
                            <span>
                              <small>
                                <span
                                  class="px-50 text-white rounded-circle mr-25"
                                  :class="parseInt(delivery.n_ddt_scaricati) === parseInt(delivery.n_ddt_in_arxivar) ? 'bg-success' : 'bg-warning'"
                                />

                                <strong>DDT SCARICATI:</strong> {{
                                  delivery.n_ddt_scaricati
                                }} su {{ delivery.n_ddt_in_arxivar }}
                              </small>
                            </span>
                          </div>
                          <div
                            v-else
                            class="my-1"
                          >
                            <span>
                              <small>
                                <span
                                  class="px-50 text-white rounded-circle bg-danger mr-25"
                                />
                                <span class="text-danger">
                                  <strong>ANOMALIA: DDT in Arxivar </strong> {{
                                    parseInt(delivery.n_ddt_in_arxivar)
                                  }} su {{ delivery.ddt.length }}
                                </span>
                              </small>
                            </span>
                          </div>

                          <span class="ml-50">
                            <feather-icon
                              class="cursor-pointer"
                              icon="RefreshCcwIcon"
                              @click="loadDelivery"
                            />
                          </span>

                          <div
                            v-if="parseInt(delivery.n_ddt_in_errore)"
                            class="mt-1 pr-1 w-100"
                          >
                            <span class="text-danger">
                              <small>
                                <feather-icon
                                  class="mr-25 text-danger"
                                  icon="AlertTriangleIcon"
                                  size="13"
                                />
                                <strong>DDT IN ERRORE:</strong> {{ delivery.n_ddt_in_errore }}
                              </small>
                            </span>
                          </div>

                          <div
                            v-if="(parseInt(delivery.n_ddt_in_arxivar) === parseInt(delivery.n_ddt_firmati))
                              && (parseInt(delivery.n_ddt_in_arxivar) === delivery.ddt.length)"
                            class="mt-0 mb-1 text-left w-100"
                          >
                            <b-badge
                              v-if="(parseInt(delivery.n_ddt_in_arxivar) === 0 && delivery.ddt.length === 0)"
                              variant="warning"
                            >
                              <feather-icon
                                icon="FileIcon"
                                class="mr-25"
                              />
                              <span>Nessun DDT da firmare</span>
                            </b-badge>

                            <b-badge
                              v-else
                              variant="success"
                            >
                              <feather-icon
                                icon="FileIcon"
                                class="mr-25"
                              />
                              <span>Tutti i DDT sono firmati</span>
                            </b-badge>
                          </div>

                        </b-col>

                      </b-row>
                      <!--
                       <div
                         v-if="delivery.ddt.length"
                         class="mt-1"
                       >
                         <strong>ELENCO DDT:</strong>
                         <ul class="ddt-list">
                           <li
                             v-for="(ddt, index) in delivery.ddt"
                             :key="index"
                           >
                             {{ ddt.documento_pdf }}
                           </li>
                         </ul>
                       </div>
                       -->
                      <div class="mt-1">

                        <b-row>
                          <b-col>

                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-toggle.sidebar-right
                              variant="outline-primary"
                              class=""
                              block
                              @click="setNoteData(1,
                                                  delivery.c_carico,
                                                  delivery.n_consegna,
                                                  null,
                                                  null,
                                                  null,
                                                  null,
                                                  delivery.c_causale_consegne,
                                                  delivery.fg_tipo_scarico,
                                                  delivery.nota_consegna
                              )"
                            >
                              <feather-icon
                                :icon="delivery.fg_tipo_scarico ? 'EyeIcon' : 'PlusIcon'"
                                class="mr-50"
                                size="16"
                              />
                              <span class="align-middle">NOTA CONSEGNA</span>
                            </b-button>
                          </b-col>
                          <b-col v-if="delivery.ddt.length !== 0">
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-toggle.sidebar-ddt-data
                              variant="outline-primary"
                              block
                              class=""
                            >
                              <feather-icon
                                :icon="delivery.fg_tipo_scarico ? 'EyeIcon' : 'PlusIcon'"
                                class="mr-50"
                                size="16"
                              />
                              <span class="align-middle">DATI DDT</span>
                            </b-button>
                          </b-col>
                        </b-row>

                        <div
                          v-if="delivery && delivery.fg_tipo_piano === 5"
                          class="mt-2 w-100"
                        >
                          <div
                            v-if="(parseInt(delivery.n_ddt_in_arxivar) !== parseInt(delivery.n_ddt_scaricati))"
                            class="mt-2"
                          >
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-secondary"
                              class="mr-75"
                              block
                              @click="ubsignDownloadDDT"
                            >
                              <feather-icon
                                icon="DownloadIcon"
                                class="mr-50"
                                size="16"
                              />
                              <span class="align-middle">SCARICA DDT</span>
                            </b-button>
                          </div>
                          <div v-else-if="parseInt(delivery.n_ddt_in_arxivar) !== 0">
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-secondary"
                              class="mr-75"
                              block
                              @click="openApp(delivery.c_carico, delivery.n_consegna)"
                            >
                              <feather-icon
                                icon="FileIcon"
                                class="mr-50"
                                size="16"
                              />
                              <span class="align-middle">MOSTRA DDT SCARICATI</span>
                            </b-button>
                          </div>
                        </div>

                        <b-overlay
                          :show="showOverlayPackingList"
                          variant="white"
                          spinner-small
                        >
                          <div
                            v-if="packing_lists.length"
                            class="mt-2"
                          >
                            <b-button
                              v-for="packing_list in packing_lists"
                              :key="packing_list.id_esterno"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-modal.modal-packing-list
                              variant="outline-secondary"
                              block
                              @click="getGDrivePdfFile(packing_list.id_esterno)"
                            >
                              <feather-icon
                                icon="FileTextIcon"
                                class="mr-50"
                                size="16"
                              />
                              <span class="align-middle">PACKING LIST</span>
                            </b-button>
                          </div>
                        </b-overlay>

                        <div
                          v-if="(parseInt(delivery.n_ddt_in_arxivar) !== parseInt(delivery.n_ddt_firmati))"
                          class="mt-2"
                        >
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-75 mb-75"
                            block
                            :disabled="false"
                            @click="requestSignature"
                          >
                            <feather-icon
                              :icon="delivery.ddt_controlli ? 'Edit3Icon' : 'XOctagonIcon'"
                              class="mr-50"
                              size="16"
                            />
                            <span class="align-middle">FIRMA DIGITALE</span>
                          </b-button>
                        </div>

                        <div v-if="canCloseDelivery">
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="success"
                            class="mt-1"
                            block
                            @click="setDeliveryOk"
                          >
                            <feather-icon
                              icon="CheckIcon"
                              class="mr-50"
                              size="16"
                            />
                            <span class="align-middle">TUTTO OK! CHIUDI LA CONSEGNA</span>
                          </b-button>
                        </div>

                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          class="mt-1"
                          block
                          :to="{ name: 'cargo', params: { cargo: delivery.c_carico }}"
                        >
                          INDIETRO
                        </b-button>

                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row
              v-if="delivery && delivery.notes && delivery.notes.length"
              class="no-gutters notes-row"
            >
              <b-col
                cols="12"
              >
                <app-collapse>
                  <app-collapse-item title="Note azienda">
                    <ul>
                      <li
                        v-for="(note, index) in delivery.notes"
                        :key="index"
                      >
                        {{ note }}

                      </li>
                    </ul>
                  </app-collapse-item>
                </app-collapse>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        xl="8"
        lg="8"
        md="12"
      >
        <b-overlay
          :show="showOverlayOrders"
          variant="white"
        >
          <b-card class="delivery-info-box">
            <div class="p-150">
              <b-row v-if="orders_list && delivery">
                <b-col
                  cols="12"
                  xl="12"
                  class="d-flex justify-content-between flex-column"
                >
                  <div class="">
                    <div class="">
                      <h2 class="pb-1">
                        Elenco ordini
                      </h2>
                    </div>
                  </div>

                  <!-- Elenco ordini -->
                  <b-list-group
                    v-if="orders_list && orders_list.length"
                    class="orders"
                  >
                    <b-list-group-item
                      v-for="listItem in orders_list"
                      :key="`${listItem.n_ordine}`"
                      class="w-100 mb-2 order "
                      :class="listItem.slug_tipo_scarico"
                      tag="li"
                    >
                      <b-row class="no-gutters">
                        <b-col
                          cols="3"
                          sm="2"
                          class="d-flex align-content-start justify-content-start flex-wrap py-1"
                        >
                          <div class="primary text-center w-100 p-1">
                            <div
                              v-b-tooltip.hover.v-dark
                              title="Riferimento scarico"
                              class="rif-scarico-code d-inline-flex align-self-center align-content-center justify-content-center "
                            >
                              <div class="d-inline-flex align-self-center">
                                {{ listItem.rif_scarico_oct }}
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <b-col
                          cols="9"
                          sm="7"
                          class="d-flex align-content-center justify-content-left flex-wrap p-1"
                        >
                          <div class="primary w-100">
                            <strong>
                              ORDINE: {{ `${listItem.n_ordine}-${listItem.c_numeratore}-${listItem.n_anno}` }}
                            </strong>
                          </div>

                          <div class="address mt-25 w-100">
                            <span class="text-important">
                              <strong>RIF.CLIENTE:</strong> {{ listItem.riferimento_cliente }}
                            </span>
                          </div>
                          <b-row class="no-gutters w-100">
                            <b-col
                              cols="12"
                              class="d-flex align-items-center justify-content-start justify-content-start flex-wrap pt-1"
                            >
                              <div
                                v-b-tooltip.hover.v-dark
                                title="Numero colli"
                                class="pr-1"
                              >
                                <small>{{ parseInt(listItem.packages.length) }}</small>
                                <feather-icon
                                  class="text-body"
                                  icon="PackageIcon"
                                  size="21"
                                />
                              </div>

                              <div
                                v-b-tooltip.hover.v-dark
                                title="Volume"
                                class="pb-50 pr-1"
                              >
                                <small>{{ parseFloat(listItem.n_volume) + 'm³' }}</small>
                                <feather-icon
                                  class="text-body"
                                  icon="BoxIcon"
                                  size="21"
                                />
                              </div>
                            </b-col>

                          </b-row>
                        </b-col>

                        <b-col
                          cols="12"
                          class="d-none align-content-start justify-content-left flex-wrap p-1"
                        >
                          <div
                            v-if="listItem.ddt.length"
                            class="mt-1"
                          >
                            <strong>ELENCO DDT:</strong>
                            <ul class="ddt-list">
                              <li
                                v-for="(ddt, index) in listItem.ddt"
                                :key="index"
                              >
                                {{ ddt.documento_pdf }}
                              </li>
                            </ul>
                          </div>
                        </b-col>

                        <b-col
                          cols="12"
                          sm="3"
                          class="d-flex align-content-center justify-content-left flex-wrap p-1"
                        >

                          <b-button
                            v-b-modal.modal-ddt
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            title="DDT"
                            block
                            variant="outline-secondary"
                            @click="getOrderData(listItem.rif_scarico_oct)"
                          >
                            <feather-icon
                              icon="FileIcon"
                              class="mr-50"
                              size="16"
                            />
                            <span class="align-middle">ELENCO DDT</span>
                          </b-button>

                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-toggle.sidebar-right
                            block
                            variant="outline-primary"
                            @click="setNoteData(2,
                                                listItem.c_carico,
                                                listItem.n_consegna,
                                                listItem.n_anno,
                                                listItem.c_numeratore,
                                                listItem.n_ordine,
                                                null,
                                                listItem.c_causale_consegne,
                                                listItem.fg_tipo_scarico,
                                                listItem.nota_consegna
                            )"
                          >
                            <feather-icon
                              :icon="listItem.fg_tipo_scarico ? 'EyeIcon' : 'PlusIcon'"
                              class="mr-50"
                              size="16"
                            />
                            <span class="align-middle">NOTA ORDINE</span>
                          </b-button>

                          <b-button
                            v-if="parseInt(listItem.con_conferma) === 1"
                            v-b-modal.modal-confirm-delivery
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            title="CONFERMA CONSEGNA"
                            block
                            variant="warning"
                            @click="setDeliveryConfirmData(listItem.c_carico,
                                                           listItem.n_anno,
                                                           listItem.c_numeratore,
                                                           listItem.n_ordine,
                                                           listItem.nota_conferma,
                                                           listItem.importo_netto,
                                                           listItem.dt_bolla,
                                                           listItem.riferimento_cliente,
                                                           listItem.ragione_sociale
                            )"
                          >
                            <feather-icon
                              icon="CheckIcon"
                              class="mr-50"
                              size="16"
                            />
                            <span class="align-middle">CONFERMA CONSEGNA</span>
                          </b-button>

                        </b-col>

                      </b-row>
                      <b-row
                        v-if="listItem.packages && listItem.packages.length"
                        class="no-gutters packages-row"
                      >
                        <b-col
                          cols="12"
                        >
                          <app-collapse>
                            <app-collapse-item title="VISUALIZZA COLLI">
                              <div class="packages-list">
                                <b-row
                                  v-for="(pack, index) in listItem.packages"
                                  :key="index"
                                  :class="pack.slug_tipo_scarico"
                                  class="border-bottom align-items-center pack"
                                >
                                  <b-col
                                    cols="4"
                                    sm="3"
                                    class="py-75"
                                  >
                                    <span
                                      v-b-tooltip.hover.v-dark
                                      class="pack-code"
                                      title="ID Collo"
                                    >
                                      {{ pack.c_collo }}
                                    </span>
                                  </b-col>
                                  <b-col
                                    cols="8"
                                    sm="6"
                                    class="py-75"
                                  >
                                    <span
                                      v-b-tooltip.hover.v-dark
                                      title="Descrizione collo"
                                    >
                                      {{ pack.ds_estesa }}
                                    </span>
                                  </b-col>
                                  <b-col
                                    cols="12"
                                    sm="3"
                                    class="py-75 text-right"
                                  >
                                    <b-button
                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                      v-b-toggle.sidebar-right
                                      variant="outline-primary"
                                      @click="setNoteData(3,
                                                          listItem.c_carico,
                                                          listItem.n_consegna,
                                                          listItem.n_anno,
                                                          listItem.c_numeratore,
                                                          listItem.n_ordine,
                                                          pack.c_collo,
                                                          pack.c_causale_consegne,
                                                          pack.fg_tipo_scarico,
                                                          pack.nota_consegna
                                      )"
                                    >
                                      <feather-icon
                                        :icon="pack.fg_tipo_scarico ? 'EyeIcon' : 'PlusIcon'"
                                        class="mr-50"
                                        size="16"
                                      />
                                      <span class="align-middle">NOTA COLLO</span>
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </div>
                            </app-collapse-item>
                          </app-collapse>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                  <!-- END | Elenco consegne -->
                </b-col>
              </b-row>
            </div>

          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <!-- Modal - Packing List -->
    <b-modal
      id="modal-packing-list"
      ok-only
      ok-title="Chiudi"
      centered
      size="lg"
      title="Packing List"
    >
      <b-card-text>
        <b-overlay
          :show="showOverlayPackingListPdf"
          variant="white"
        >
          <div class="packing-list-pdf-wrapper">
            <div v-if="packing_list_pdf">
              <vue-pdf-app
                :config="pdf_viewer_config"
                :pdf="packing_list_pdf"
              />
            </div>
          </div>
        </b-overlay>
      </b-card-text>
    </b-modal>
    <!-- END | Modal - Packing List -->

    <!-- Sidebar - Form Nota -->
    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      no-header
      backdrop
      right
      :visible="showNoteSidebar"
      @change="updateNoteSidebar"
    >
      <template #default="{ hide }">
        <!-- Sidebar Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Nota
            <span
              v-if="note_data.fg_livello === 1 && note_data.n_scarico"
            >
              | Consegna <strong>{{ note_data.n_scarico }}</strong>
            </span>
            <span
              v-else-if="note_data.fg_livello === 2 && note_data.n_ordine && note_data.n_anno && note_data.c_numeratore"
            >
              | Ordine <strong>{{ `${note_data.n_ordine}-${note_data.c_numeratore}-${note_data.n_anno}` }}</strong>
            </span>
            <span
              v-else-if="note_data.fg_livello === 3 && note_data.c_collo"
            >
              | Collo <strong>{{ `${note_data.c_collo}` }}</strong>
            </span>
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <notes-form
          :hide="hide"
          :note_data="note_data"
          :read_only="NotesReadOnly"
          @noteSubmitted="noteReload"
        />
      </template>
    </b-sidebar>
    <!-- END | Sidebar - Form Aggiungi Nota -->

    <!-- Sidebar - Form Dati DDT -->
    <b-sidebar
      v-if="delivery"
      id="sidebar-ddt-data"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      no-header
      backdrop
      right
      :visible="showDDTDataSidebar"
      @change="updateDDTDataSidebar"
    >
      <template #default="{ hide }">
        <!-- Sidebar Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Dati DDT | Carico <strong>{{ delivery.c_carico }}</strong> | Consegna <strong>{{
              delivery.n_consegna
            }}</strong>
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <ddt-data-form
          :hide="hide"
          :c_carico="delivery.c_carico"
          :n_scarico="parseInt(delivery.n_consegna)"
          :driver="ddtDriver"
          :receiver="delivery.ddt_ricevente"
          :checks="delivery.ddt_controlli"
          :ddt="delivery.ddt"
          :c_cliente="parseInt(delivery.c_nome_consegna)"
          :pin_required="delivery.pin_conferma_cliente"
          :pin_status="delivery.pin_conferma_cliente_stato"
          :pin="delivery.pin_conferma_cliente_inserito"
          :read_only="NotesReadOnly"
          @ddtDataSubmitted="loadDelivery"
        />
      </template>
    </b-sidebar>
    <!-- END | Sidebar - Form Dati DDT -->

    <!-- Modale contatti utili -->
    <b-modal
      id="modal-contacts"
      title="Contatti utili"
      centered
      ok-only
      ok-variant="outline-secondary"
      ok-title="Chiudi"
    >
      <b-card-text
        v-if="delivery"
      >
        <div class="mt-1">
          <div
            v-if="delivery.telefono_capo_area"
            class="mb-1 pb-1 border-bottom"
          >
            <h4 class="mb-50">
              CAPO AREA
            </h4>
            <strong>{{ `${delivery.ds_capo_area}` }}</strong><br>
            {{ `Tel.: 041 5899${delivery.telefono_capo_area}` }}
          </div>
          <div
            v-if="delivery.ds_corrispondente_reclami"
            class="mb-1 pb-1 border-bottom"
          >
            <h4 class="mb-50">
              REFERENTE RECLAMI
            </h4>
            <strong>{{ `${delivery.ds_corrispondente_reclami}` }}</strong><br>
            {{ `Tel.: 041 5899${delivery.tel_corrispondente_reclami}` }}<br>
            {{ `E-mail: ${delivery.email_corrispondente_reclami}` }}
          </div>
          <div
            v-if="delivery.telefono_agente"
            class="mb-1 pb-1 border-bottom"
          >
            <h4 class="mb-50">
              AGENTE
            </h4>
            {{ `${delivery.ds_agente}` }}<br>
            {{ `Tel.: ${delivery.telefono_agente}` }}
          </div>
          <div
            v-if="delivery.client_contacts.length"
            class="mb-1 pb-1 border-bottom"
          >
            <h4 class="mb-50">
              CONTATTI DEL CLIENTE
            </h4>
            <div
              v-for="contact in delivery.client_contacts"
              :key="contact.PK"
              class="mb-50"
            >
              <div v-if="contact.n_telefono">
                <span v-if="contact.contatto"><strong>{{ `${contact.contatto}` }}</strong><br></span>
                Tel.: {{ contact.n_telefono }}
                <span v-if="contact.ds_raggr_tipi_doc">{{ `[${contact.ds_raggr_tipi_doc}]` }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-card-text>
    </b-modal>
    <!-- END | Modale contatti utili -->

    <!-- Modale elenco DDT -->
    <b-modal
      id="modal-ddt"
      title="DDT"
      centered
      size="lg"
      ok-only
      ok-variant="outline-secondary"
      ok-title="Chiudi"
    >
      <b-overlay
        :show="showOverlayOrders"
        variant="white"
      >
        <b-card-text class="modal-content-wrapper">
          <div v-if="order_data && order_data.ddt && order_data.ddt.length">
            <table class="mt-2 mt-xl-0 w-100 delivery-orders-table">
              <tr>
                <th class="p-50">
                  <span class="font-weight-bold">DDT</span>
                </th>
                <th class="p-50">
                  <span class="font-weight-bold">AZIONI</span>
                </th>
              </tr>
              <tr
                v-for="ddt in order_data.ddt"
                :key="ddt.n_bolla"
              >
                <td class="p-50">
                  {{ `${ddt.documento_pdf}` }}
                </td>
                <td class="p-50">
                  <a
                    @click="ubsignOpenDDT(ddt.n_anno_bolla_interno, ddt.c_numeratore_bolla_interno, ddt.n_bolla_interno)"
                  >Apri</a>
                </td>
              </tr>
            </table>
          </div>
        </b-card-text>
      </b-overlay>
    </b-modal>
    <!-- END | Modale elenco DDT -->

    <!-- Modale conferma consegna ordine -->
    <b-modal
      id="modal-confirm-delivery"
      ref="confirm-modal"
      centered
      title="Conferma la consegna"
      ok-title="CONFERMA"
      cancel-title="CHIUDI"
      cancel-variant="outline-secondary"
      @hidden="resetConfirmModal"
      @ok="handleConfirmOk"
    >

      <b-overlay
        :show="showOverlayConfirmModal"
        variant="white"
      >
        <p>Conferma la consegna di questo ordine.</p>
        <p>
          <span v-if="delivery_confirm_data.riferimento_cliente"><strong>RIFERIMENTO:</strong> {{
            delivery_confirm_data.riferimento_cliente }}<br></span>
          <!--
          <span v-if="delivery_confirm_data.dt_bolla"><strong>DATA:</strong> {{
            new Intl.DateTimeFormat('en-GB').format(Date.parse(delivery_confirm_data.dt_bolla))
          }}<br></span>
          <span v-if="delivery_confirm_data.ragione_sociale"><strong>CLIENTE:</strong> {{
            delivery_confirm_data.ragione_sociale
          }}<br></span>
          <span v-if="delivery_confirm_data.riferimento_cliente"><strong>RIFERIMENTO:</strong> {{
            delivery_confirm_data.riferimento_cliente
          }}<br></span>
          <span v-if="delivery_confirm_data.importo_netto"><strong>ARTICOLO:</strong> {{
            delivery_confirm_data.importo_netto
          }}</span>
          -->
        </p>
        <form
          ref="form-confirm"
          @submit.stop.prevent="handleConfirmSubmit"
        >
          <b-form-group
            label="Nota"
            label-for="note-input"
          >
            <b-form-input
              id="note-input"
              v-model="delivery_confirm_data.note"
            />
          </b-form-group>
        </form>
      </b-overlay>
    </b-modal>
    <!-- END | Modale conferma consegna ordine -->

  </div>

</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BPagination,
  BFormGroup,
  BAlert,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardText,
  BOverlay,
  BListGroupItem,
  BListGroup,
  VBTooltip,
  BModal,
  VBModal,
  BButton,
  BSidebar,
  BFormCheckbox,
  BFormCheckboxGroup,
  VBToggle,
  BCalendar,
  BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePdfApp from 'vue-pdf-app'
import { openApp } from '@mixins/ubsign'
import DeliveryMap from './DeliveryMap'
import NotesForm from '../../forms/NotesForm'
import DdtDataForm from '../../forms/DDTDataForm'
import 'vue-pdf-app/dist/icons/main.css'
import appConfig from '../../../../../config.json'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardText,
    BOverlay,
    BListGroupItem,
    BListGroup,
    BAlert,
    VBTooltip,
    BModal,
    VBModal,
    BButton,
    VBToggle,
    BSidebar,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
    BFormCheckboxGroup,
    BCalendar,
    DeliveryMap,
    BBreadcrumb,
    BBreadcrumbItem,
    NotesForm,
    DdtDataForm,
    VuePdfApp,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [openApp],
  data() {
    return {
      locale: 'it',
      dir: false,
      emptyTableStatus: 'loading',
      showOverlay: false,
      showOverlayOrders: false,
      showOverlayPackingList: false,
      showOverlayPackingListPdf: false,
      showOverlayConfirmModal: false,
      delivery: null,
      delivery_checks: null,
      orders_list: null,
      order_data: null,
      packing_lists: [],
      packing_list_pdf: null,
      showNoteSidebar: false,
      showDDTDataSidebar: false,
      control_pin_enabled: appConfig.control_pin_enabled ?? false,
      pdf_viewer_config: {
        toolbar: {
          toolbarViewerRight: {
            openFile: false,
            print: true,
            download: true,
            viewBookmark: false,
            cursorHandTool: false,
          },
          toolbarViewerLeft: {
            pageNumber: false,
            numPages: false,
          },
        },
      },
      note_data: {
        fg_livello: null,
        c_carico: null,
        n_scarico: null,
        n_anno: null,
        c_numeratore: null,
        n_ordine: null,
        c_collo: null,
        c_causale_consegne: null,
        fg_tipo_scarico: null,
        nota_consegna: null,
      },
      delivery_confirm_data: {
        c_carico: null,
        n_anno: null,
        c_numeratore: null,
        n_ordine: null,
        note: null,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'Da configurare': 'light-dark',
        'In fase di configurazione': 'light-warning',
        'Configurazione conclusa': 'light-info',
        'Configurazione conclusa con avvisi': 'light-danger',
        'Configurazione confermata': 'light-success',
        'Configurazione rifiutata': 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    canCloseDelivery() {
      let ddtOk = false

      if (this.delivery) {
        const ddtLen = this.delivery.ddt.length ?? 0
        // eslint-disable-next-line radix
        const ddtSigned = parseInt(this.delivery.n_ddt_firmati)
        // eslint-disable-next-line radix
        const ddtArxivar = parseInt(this.delivery.n_ddt_in_arxivar)

        // 22/12/2022 - FV | Bugfix | Sarà possibile chiudere la consegna se tutti i DDT sono firmati senza verificare la presenza di dt_firma_ddt
        /*
        if (ddtLen === ddtSigned && ddtLen === ddtArxivar && (this.delivery.dt_firma_ddt || this.delivery.ddt.length === 0)) {
          ddtOk = true
        }
        */

        if (ddtLen === ddtSigned && ddtLen === ddtArxivar) {
          ddtOk = true
        }
      }

      let orderOk = true

      if (this.orders_list) {
        // eslint-disable-next-line radix
        const ordersToConfirm = this.orders_list.filter(order => parseInt(order.con_conferma) === 1)
        const ordersToConfirmLen = ordersToConfirm.length

        if (ordersToConfirmLen) {
          orderOk = false
        }

        // eslint-disable-next-line radix
        const ordersConfirmed = this.orders_list.filter(order => parseInt(order.ordine_confermato) === 1 && parseInt(order.con_conferma) === 1)
        const orderConfirmedLen = ordersConfirmed.length

        if (ordersToConfirmLen === orderConfirmedLen) {
          orderOk = true
        }
      }

      if (ddtOk && orderOk && !parseInt(this.delivery.fg_consegnato)) {
        return true
      }
      return false
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ddtDriver() {
      if (this.delivery.ddt_autista) {
        return this.delivery.ddt_autista
      }
      if (this.delivery.trasportatore) {
        return this.delivery.trasportatore.replace(/[0-9]/g, '').trim()
      }

      return ''
    },
    NotesReadOnly() {
      if (this.delivery) {
        // eslint-disable-next-line radix
        if (parseInt(this.delivery.fg_richiesta_firma_ddt)) {
          return true
        }

        // eslint-disable-next-line radix
        if (parseInt(this.delivery.fg_consegnato)) {
          return true
        }
      }

      return false
    },
  },
  watch: {
    delivery: {
      handler(newValue) {
        this.loadClientPackingList(newValue.c_nome_consegna)
      },
      deep: true,
    },
  },
  created() {
    this.loadDelivery()
    this.loadOrders()
  },
  methods: {
    loadDelivery() {
      this.showOverlay = true

      /* Carico le info del carico */
      this.$http.get(`/cargo/in-progress/delivery/${this.$route.params.cargo}/${this.$route.params.delivery}/info`).then(res => {
        this.delivery = res.data.data.delivery

        /* Recupero i dettagli sui controlli effettuati */
        return this.$http.post('/cargo/delivery/checks', { checks: this.delivery.ddt_controlli ?? '' })
      }).then(res => {
        this.delivery_checks = res.data.data.checks
        this.showOverlay = false
      }).catch(() => {
        this.showOverlay = false
      })
    },
    loadOrders(callback) {
      this.showOverlayOrders = true

      /* Carico l'eleco degli ordini */
      this.$http.get(`/cargo/in-progress/delivery/${this.$route.params.cargo}/${this.$route.params.delivery}/orders`).then(res => {
        this.orders_list = res.data.data.orders
        this.showOverlayOrders = false

        callback()
      }).catch(() => {
        this.showOverlayOrders = false
      })
    },
    loadClientPackingList(c_nome_consegna) {
      this.packing_lists = []
      if (this.delivery && this.delivery.c_nome_consegna) {
        this.showOverlayPackingList = true
        /* Carico l'elenco dei Packing List */
        this.$http.get(`/cargo/packing-lists/${this.$route.params.cargo}/${c_nome_consegna}`).then(res => {
          this.packing_lists = res.data.data.packing_lists
          this.showOverlayPackingList = false
        }).catch(() => {
          this.showOverlayPackingList = false
        })
      }
    },
    ubsignDownloadDDT() {
      this.showOverlay = true
      const submitData = {
        c_carico: this.delivery.c_carico,
        n_scarico: this.delivery.n_consegna,
      }
      this.$http.post('/ubsign/download', submitData).then(res => {
        this.showOverlay = false
        this.openApp(this.delivery.c_carico, this.delivery.n_consegna)
      }).catch(error => {
        this.showOverlay = false
      })
    },
    // eslint-disable-next-line camelcase
    ubsignOpenDDT(n_anno_bolla_interno, c_numeratore_bolla_interno, n_bolla_interno) {
      this.showOverlayOrderDDTList = true
      const submitData = {
        c_carico: this.delivery.c_carico,
        n_anno_bolla_interno,
        c_numeratore_bolla_interno,
        n_bolla_interno,
      }
      this.$http.post('/ubsign/open', submitData).then(res => {
        this.showOverlayOrderDDTList = false
        this.openApp(this.delivery.c_carico)
      }).catch(error => {
        this.showOverlayOrderDDTList = false
      })
    },
    requestSignature() {
      if (this.delivery.ddt_controlli) {
        let message = '<h4 class="text-danger">Verifica i dati inseriti.</h4><strong>ATTENZIONE:</strong> Dopo la conferma non potrai più modificarli.'

        message = '<div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger">\n'
          + '\t<div class="alert-body">\n'
          + '<span><strong>ATTENZIONE!</strong> Verifica i dati inseriti. Dopo la conferma non potrai più modificarli.<span>'
          + '\t</div>\n'
          + '</div>'

        if (this.control_pin_enabled && this.delivery.pin_conferma_cliente_stato && this.delivery.pin_conferma_cliente_stato === 'wrong') {
          message += '<div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-warning">\n'
            + '\t<div class="alert-body">\n'
            + '<span><strong>ATTENZIONE!</strong> Il pin di controllo inserito risulta errato.<span>'
            + '\t</div>\n'
            + '</div>'
        }

        if (this.control_pin_enabled && this.delivery.pin_conferma_cliente_stato && this.delivery.pin_conferma_cliente_stato === 'empty') {
          message += '<div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-warning">\n'
            + '\t<div class="alert-body">\n'
            + '<span><strong>ATTENZIONE!</strong> Non è stato inserito il pin di controllo richiesto.<span>'
            + '\t</div>\n'
            + '</div>'
        }

        if (this.control_pin_enabled && this.delivery.pin_conferma_cliente_stato && this.delivery.pin_conferma_cliente_stato === 'valid') {
          message += `${'<div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-success">\n'
            + '\t<div class="alert-body">\n'
            + '<span><strong>OTTIMO!</strong> Il pin di controllo inserito è corretto! ['}${this.delivery.pin_conferma_cliente_inserito}]<span>`
            + '\t</div>\n'
            + '</div>'
        }

        message += `<strong>AUTISTA:</strong> ${this.delivery.ddt_autista}`
        message += `<br><strong>RICEVENTE:</strong> ${this.delivery.ddt_ricevente}`

        if (this.delivery_checks.length) {
          message += '<table class="table mt-2" >'
          message += '<thead><tr><th scope="col" colspan="2">Controlli effettuati</th></tr></thead>'

          this.delivery_checks.forEach((check, index, checks) => {
            message += '<tr>'
            message += `<th scope="row">${check.ds_tipo}</th>`
            message += `<td>${check.ds_valore}</td>`
            message += '</tr>'
          })
          message += '</table>'
        }

        message = this.$createElement('div', { domProps: { innerHTML: message } })

        this.$bvModal
          .msgBoxConfirm(message, {
            title: 'Sei sicuro?',
            size: 'lg',
            okVariant: 'success',
            okTitle: 'CONFERMA',
            cancelTitle: 'ANNULLA',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value === true) {
              const data = {
                c_carico: this.delivery.c_carico,
                n_scarico: this.delivery.n_consegna,
                driver: this.delivery.ddt_autista,
                receiver: this.delivery.ddt_ricevente,
                checks: this.delivery.ddt_controlli,
              }

              this.showOverlay = true

              // Save DDT Data and Notes in UBSign DB
              this.$http.post('/ubsign/note', data).then(res => {
                this.showOverlay = false
                this.$emit('requestSignature')

                // Open Ubsign
                this.openApp(data.c_carico, data.n_scarico)
                this.loadDelivery()
                this.loadOrders()
              }).catch(() => {
                this.showOverlay = false
              })
            }
          })
      } else {
        this.$bvModal
          .msgBoxOk('Inserire i dati DDT prima di firmare i documenti.', {
            title: 'Dati DDT non inseriti',
            size: 'sm',
            buttonSize: 'md',
            okVariant: 'secondary',
            okTitle: 'CHIUDI',
            hideHeaderClose: false,
            centered: true,
          })
      }
    },
    // recupero i dati del'ordine selezionato
    getOrderData(order) {
      this.order_data = null
      this.order_data = this.orders_list.filter(el => el.rif_scarico_oct === order)[0]
    },
    noteReload(note) {
      if (note.fg_livello === 2 || note.fg_livello === 3) {
        this.loadOrders()
      } else {
        this.loadDelivery()
      }
    },
    // eslint-disable-next-line no-unused-vars
    setNoteData(FgLivello = null, CCarico = null, NScarico = null, NAnno = null, CNumeratore = null, NOrdine = null, CCollo = null, CCausaleConsegne = null, FgTipoScarico = null, NotaConsegna = null) {
      this.note_data.fg_livello = FgLivello
      this.note_data.c_carico = CCarico
      this.note_data.n_scarico = NScarico
      this.note_data.n_anno = NAnno
      this.note_data.c_numeratore = CNumeratore
      this.note_data.n_ordine = NOrdine
      this.note_data.c_collo = CCollo
      this.note_data.c_causale_consegne = CCausaleConsegne
      this.note_data.fg_tipo_scarico = FgTipoScarico
      this.note_data.nota_consegna = NotaConsegna
    },
    updateNoteSidebar() {
      this.showNoteSidebar = !this.showNoteSidebar
    },
    updateDDTDataSidebar() {
      this.showDDTDataSidebar = !this.showDDTDataSidebar
    },
    getGDrivePdfFile(fileID) {
      this.showOverlayPackingListPdf = true
      this.packing_list_pdf = null
      this.$http.get(`/gdrive/getfile/${fileID}`)
        .then(async response => {
          // loading.close()
          const pdfBlob = this.dataURItoBlob(`data:application/pdf;base64,${response.data}`)
          this.packing_list_pdf = await pdfBlob.arrayBuffer()
          this.showOverlayPackingListPdf = false
        }).catch(() => {
          this.showOverlayPackingListPdf = false
        })
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1])
      const mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      // write the ArrayBuffer to a blob, and you're done
      return new Blob([ab], { type: mimeString })
    },
    setDeliveryConfirmData(CCarico = null, NAnno = null, CNumeratore = null, NOrdine = null, Note, netAmount, dtBolla, rifCliente, ragSociale) {
      this.delivery_confirm_data.c_carico = CCarico
      this.delivery_confirm_data.n_anno = NAnno
      this.delivery_confirm_data.c_numeratore = CNumeratore
      this.delivery_confirm_data.n_ordine = NOrdine
      this.delivery_confirm_data.note = Note ?? 'Tutto ok!'
      this.delivery_confirm_data.importo_netto = netAmount ?? 0
      this.delivery_confirm_data.dt_bolla = dtBolla ?? ''
      this.delivery_confirm_data.riferimento_cliente = rifCliente ?? ''
      this.delivery_confirm_data.ragione_sociale = ragSociale ?? ''
    },
    resetConfirmModal() {
      this.delivery_confirm_data.c_carico = null
      this.delivery_confirm_data.n_anno = null
      this.delivery_confirm_data.c_numeratore = null
      this.delivery_confirm_data.n_ordine = null
      this.delivery_confirm_data.note = null
      this.delivery_confirm_data.importo_netto = null
      this.delivery_confirm_data.dt_bolla = null
      this.delivery_confirm_data.riferimento_cliente = null
      this.delivery_confirm_data.ragione_sociale = null
    },
    handleConfirmOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleConfirmSubmit()
    },
    handleConfirmSubmit() {
      this.showOverlayList = true
      this.showOverlayConfirmModal = true
      this.showOverlayOrders = true
      const submitData = { ...this.delivery_confirm_data }

      this.$http.post('/cargo/delivery/order/confirm', submitData).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Consegna confermata',
            icon: 'CheckIcon',
            variant: 'success',
            text: `L\'ordine ${submitData.n_anno}-${submitData.c_numeratore}-${submitData.n_ordine} è stato consegnato.`,
          },
        })
        this.showOverlayList = false
        this.showOverlayConfirmModal = false
        this.showOverlayOrders = false
        this.showOverlay = true
        this.loadOrders(this.loadDelivery)
        this.$nextTick(() => {
          this.$refs['confirm-modal'].toggle('#toggle-btn')
        })
      }).catch(() => {
        this.showOverlayList = false
        this.showOverlayConfirmModal = false
        this.showOverlayOrders = false
      })
    },
    setDeliveryOk() {
      this.showOverlay = true

      const obj = {
        c_carico: this.$route.params.cargo,
        n_scarico: this.$route.params.delivery,
      }
      // eslint-disable-next-line no-shadow
      return this.$http.post('/cargo/delivery/set-ok', obj).then(res => {
        this.showOverlay = false
        this.loadDelivery()
        this.loadOrders()
      }).catch(() => {
        this.showOverlay = false
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/core.scss";
@import "~/src/assets/scss/variables/_variables.scss";

.page-delivery {
  .delivery-info-box {
    min-height: 260px !important;

    .card-body {
      padding: 0;
    }
  }

  .ddt-list {
    padding-left: 1.2rem;
  }

  .rif-scarico-code {
    padding: 4px;
    font-weight: bold;
    background: #222222;
    color: #ffffff;
    font-size: 1.5rem;
    text-align: center;
    min-height: 50px;
    min-width: 50px;
  }

  .pack-code {
    font-weight: bold;
    background: white;
    padding: 2px 6px;
  }

  @include media-breakpoint-only(xs) {
    .packages-list {
      .btn {
        padding: 5px;
        width: 100%;
      }
    }
  }

  .list-group-item {
    /* transition: all 0; */
    padding: 0;

    &:hover {
      background-color: inherit !important;
    }

    &.order {
      border-top-width: 1px !important;
      border: 1px solid #cccccc;
      padding: 0;

      .address {
        line-height: 1;
      }
    }
  }

  .notes-row {
    .collapse-default {
      .card {
        .card-header {
          border-radius: 0;
          background: $highlighted-yellow;
          color: $dark;
          padding: 0.5rem 2.8rem 0.5rem 1rem;

          .collapse-title {
            text-transform: uppercase;
            font-size: 0.85rem;
          }

          &:before {
            content: "";
            position: absolute;
            top: -5px;
            left: 50%;
            display: block;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            background: #ffffff;
          }

        }

        .card-body {
          background: #f3f3f3;
          padding-top: 1rem;
        }
      }
    }
  }

  .packages-row {

    .collapse-default {
      .card {
        .card-header {
          border-radius: 0;
          background: $secondary;
          color: $white;
          padding: 0.5rem 2.8rem 0.5rem 1rem;

          .collapse-title {
            text-transform: uppercase;
            font-size: 0.85rem;
          }
        }

        .card-body {
          background: #f3f3f3;
          padding-top: 1rem;
        }
      }
    }

    .collapse-icon [data-toggle=collapse]:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
    }

  }

  @include media-breakpoint-down(lg) {
    .notes-row {
      .collapse-default {
        .card {
          .card-header {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }

  .delivery-orders-table {
    tr {
      &.agg {
        background: $highlighted-yellow;
      }
    }
  }

  .badge.badge-up {
    right: -13px;
  }

  .modal-content-wrapper {
    min-height: 200px;
  }

  .b-calendar {
    .b-calendar-inner {
      min-width: 230px;
    }
  }

  .btn-next {
    background: #ededed;
    border-radius: 0 !important;

    &.border-0 {
      border: none !important;
    }
  }

  @include media-breakpoint-up(sm) {
    .order {
      .btn {
        padding: 5px 5px;
        font-size: 12px;
      }
    }
  }

  .orders {
    &.list-group {
      .list-group-item {

        &.senza-anomalie {
          background-color: $senza-anomalie-bg !important;

          &:hover {
            background-color: $senza-anomalie-bg !important;
          }
        }

        &.non-effettuato {
          background-color: $non-effettuato-bg !important;

          &:hover {
            background-color: $non-effettuato-bg !important;
          }
        }

        &.con-riserva {
          background-color: $con-riserva-bg !important;

          &:hover {
            background-color: $con-riserva-bg !important;
          }
        }

        &.cliente-assente {
          background-color: $cliente-assente-bg !important;

          &:hover {
            background-color: $cliente-assente-bg !important;
          }
        }

        &.semplice-nota {
          background-color: $semplice-nota-bg !important;

          &:hover {
            background-color: $semplice-nota-bg !important;
          }
        }
      }

      .packages-row {
        .card-body {
          padding-top: 0px;
          padding-bottom: 0px;
          padding-left: 1rem;
          padding-right: 1rem;

          .pack {
            border-color: #cccccc !important;
            color: #222222;

            &.senza-anomalie {
              background-color: $senza-anomalie-bg !important;

              &:hover {
                background-color: $senza-anomalie-bg !important;
              }
            }

            &.non-effettuato {
              background-color: $non-effettuato-bg !important;

              &:hover {
                background-color: $non-effettuato-bg !important;
              }
            }

            &.con-riserva {
              background-color: $con-riserva-bg !important;

              &:hover {
                background-color: $con-riserva-bg !important;
              }
            }

            &.cliente-assente {
              background-color: $cliente-assente-bg !important;

              &:hover {
                background-color: $cliente-assente-bg !important;
              }
            }

            &.semplice-nota {
              background-color: $semplice-nota-bg !important;

              &:hover {
                background-color: $semplice-nota-bg !important;
              }
            }

          }
        }
      }

    }
  }
}

#modal-packing-list {
  .modal-body {
    padding: 0;
  }

  .packing-list-pdf-wrapper {
    min-height: 400px;
  }

  #vuePdfApp {
    width: 100%;
    height: calc(100vh - 240px);
    padding-bottom: 0px;
  }
}

</style>
